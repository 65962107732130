/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import React from 'react'

// Images
import redChevronRight from 'img/snel_red_chevron_right.svg'


// Components
import { Paragraph } from 'components/StyledComponents'
import CustomLink from 'components/CustomLink'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const StyledImg = styled(Plaatjie)`
  height: 240px;
  transition: 0.25s;
  border-radius: 20px;
`

const DescriptionContainer = styled.div`
  height: 67px;
  text-overflow: ellipsis;
  overflow: hidden;

  & p {
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.19px;
  }

  @media (max-width: 991px) {
    height: 68px;
  }
`

const ContentContainer = styled.div``

const ArticleWrapper = styled.div``

const ImageContainer = styled.div`
  overflow: hidden;

  :hover {
    ${StyledImg} {
      transform: scale(1.2);
    }
  }
`

const Types = styled.div``

const StyledCustomLink = styled(CustomLink)``

const Article = ({ data, className }) => {
  return (
    <ArticleWrapper className={`mb-4 ${className ? `${className}` : ``}`}>
      <CustomLink to={`/nieuws/${data.slug}`}>
        <ImageContainer>
          <StyledImg image={data.recapPost.image} alt="" />
        </ImageContainer>
      </CustomLink>
      <ContentContainer className="py-3 px-2">
        <Types className="d-flex">
          {data.categories.nodes.map((item, index) => (
            <Paragraph className="font-size-s color-text-striking" key={index}>{index > 0 ? `, ${item.name}` : `${item.name}`}</Paragraph>
          ))}
        </Types>
        <CustomLink to={`/nieuws/${data.slug}`}>
          <Paragraph className="font-weight-xl pb-2">{data.title}</Paragraph>
        </CustomLink>
        <Paragraph className="font-size-s color-text-secondary">{data.date}</Paragraph>
        <DescriptionContainer>
          {parse(data.recapPost.description)}
        </DescriptionContainer>
        <StyledCustomLink to={`/nieuws/${data.slug}`} className="d-flex">
          <img className="pr-2" src={redChevronRight} alt="" />
          <Paragraph className="text-uppercase color-text-striking font-size-sm font-weight-xl">Lees verder</Paragraph>
        </StyledCustomLink>
      </ContentContainer>
    </ArticleWrapper>
  )
}

export default Article
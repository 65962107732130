import { keyframes } from 'styled-components'

export const keyFrameFilterButton = keyframes`
  30% {
    transform: scale(0.90);
  }

  60% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1.00);
  }
`

export const keyFramePulsing = keyframes`
  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
`

export const keyFrameExampleOne = keyframes`
  0% {
    height: 200px;
  }
  
  100% {
    height: 600px;
    background: orange;
  }
`
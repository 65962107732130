/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import CustomBreadCrumb from 'components/Common/Breadcrumb'
import Types from 'components/Nieuws/Types'
import Article from 'components/Nieuws/Article'
import Content from 'components/Content'

// Third Party
import { isEmpty } from 'lodash'

const NieuwsTemplate = ({ data: { page, types, nieuws }, pageContext }) => {
  const [filter, setFilter] = useState([])

  let List = null

  if (!isEmpty(filter)) {
    List = nieuws.edges.filter((n) =>
      n.node.categories.nodes.find((g) =>
        filter.map((f) => f.value).includes(g.slug)
      )
    )
  } else {
    List = nieuws.edges
  }

  return (
    <Layout>
      {/* {() => ( */}
        <>
          <SEO seo={page.seo} />
          <div className="position-relative">
            <CustomBreadCrumb
              data={page}
              className="py-2"
              pageContext={pageContext}
            />
            <div className="container py-5">
              {page.pageNews.bannerDefault.description && (
                <Content content={page.pageNews.bannerDefault.description} />
              )}
              <Types
                data={types}
                filter={filter}
                setFilter={setFilter}
                className="pb-3"
              />
              <div className="row pt-5">
                {List.map((item, index) => (
                  <Article
                    className="col-sm-6 col-lg-4"
                    key={index}
                    data={item.node}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      {/* )} */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      title
      databaseId
      pageNews {
        bannerDefault {
          description
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
    types: allWpCategory(filter: { databaseId: { ne: 1 } }) {
      edges {
        node {
          name
          slug
        }
      }
    }
    nieuws: allWpPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          slug
          title
          categories {
            nodes {
              name
              slug
            }
          }
          date(formatString: "DD MMMM YYYY", locale: "nl-NL")
          recapPost {
            title
            description
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, width: 900)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default NieuwsTemplate

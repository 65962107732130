/* eslint-disable react/no-array-index-key */
import React from 'react'

// Images
import redCross from 'img/snel_red_cross.svg'

// Styles
import { keyFrameFilterButton } from 'styles/KeyFrames'

// Components
import { Paragraph } from 'components/StyledComponents'

// Third Party
import styled from 'styled-components'

const TypesWrapper = styled.div``

const Type = styled.div`
  transition: 0.25s;
  cursor: pointer;

  ${props => props.isSelected ? `
    border: 2px solid transparent;
    background-color: ${props.theme.color.secondary};};
    position: relative;
    }
  ` : `
    border: 2px solid ${props.theme.color.main};
  `}

  ${Paragraph} {
    height: 30px;
    margin: 0;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
  }

  :active {
    animation: ${keyFrameFilterButton} 0.25s ease-in-out;
  }
`

const Cross = styled.img`
  height: 13px;
  width: 13px;
  top: -5px;
  right: -5px;
`

const Types = ({ filter, setFilter, data, className }) => {

  const handleFilter = (e, index) => {
    let element;

    if (document !== undefined) {
      element = document.getElementById(`type_${index}`)
    }

    if (filter.find((o) => o.value === element.getAttribute('value'))) {
      setFilter([...filter.filter((o) => o.value !== element.getAttribute('value'))])
    } else {
      setFilter([...filter, { value: element.getAttribute('value'), label: element.innerText }])
    }
  }

  return (
    <TypesWrapper className={`d-flex flex-wrap ${className ? `${className}` : ``}`}>
      {data.edges.map((item, index) => (
        <Type onClick={(e) => handleFilter(e, index)} isSelected={!!filter.find((o) => o.value === item.node.slug)} className="mr-2 mb-2 mr-lg-3 mb-lg-3" key={index}>
          {!!filter.find((o) => o.value === item.node.slug) && (
            <Cross onClick={(e) => handleFilter(e, index)} src={redCross} alt="" className="position-absolute d-block" />
          )}
          <Paragraph id={`type_${index}`} className="px-3 font-weight-xl d-flex justify-content-center align-items-center" value={item.node.slug}>{item.node.name}</Paragraph>
        </Type>
      ))}
    </TypesWrapper>
  )
}

export default Types